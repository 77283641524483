.tho-cp{
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 6.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: $body-color;
    &:hover, &:focus {
      color: $link-color;
    }
  }

  &--title {
    @include luf-ft-s-2;
    margin-bottom: 0.3rem;
  }

  &--name {
    display: inline-block;
    @include luf-ft-s2;
    margin-bottom: rem-calc(26);
  }

  &--mail {
    box-shadow: 0 1px 0 0 $link-color;
  }

  &--item {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    & > .row {
      height: 100%;
    }
  }

  &--itemswrapper {
    position: relative;
    height: 100%;

    @include media-breakpoint-up(md) {
      background-color: $white;
    }

    & > .row {
      height: 100%;
    }
  }

  &--items {
    background-color: $white;
    padding: 1rem;
    min-height: 100%;
    line-height: 1.5;

    @include media-breakpoint-up(sm) {
      padding: rem-calc(16) rem-calc(32);
      min-height: rem-calc(212);
      min-height: 100%;
    }

    @include media-breakpoint-up(md) {
      padding: rem-calc(30) rem-calc(20);
      padding-left: 3rem;
    }
  }

  &--image {
    position: relative;
    overflow: hidden;
    z-index: 9;

    &__wrapper {
     width: 50%;
      max-width: rem-calc(150);

      @include media-breakpoint-up(md) {
        position: absolute;
        margin-left: auto;
        max-width: rem-calc(220);
        bottom: 0;
        left: 0;
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        left: 2rem;
      }
    }
  }
}