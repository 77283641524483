.luf-m22 {
  position: absolute;
  left:0;
  top: 68vh;
  background-color: $white;
  box-shadow: 0 rem-calc(20px) rem-calc(30px) rgba(0, 0, 0, 0.5);
  border-radius: 0 rem-calc(10) rem-calc(10) 0;
  transform: translateX(-110%);
  transition-duration: 0.3s;
  transition-timing-function: $ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 102;
  max-width: 350px;

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }

  &--close {
    padding: 0;
  }

  &--content {
    margin-top: 0.5rem;
    padding-right: rem-calc($grid-gutter-width);
  }

  .luf-m22-img {
    border-top-right-radius: 0.625rem;
  }

  .luf-badge-inner {
    padding: rem-calc($grid-gutter-width);

    &.luf-badge-image {
      padding-top: 0px;
    }
  }

  .luf-tag--category {
    background-color: $black;
    color: $white;
  }

  .luf-badge-image {
    .luf-tag--category {
      position: absolute;
      top: 20px;
    }

    .luf-m22--close {
      position: absolute;
      top: 20px;
      right: 20px;

      .luf-navicon {
        &.open {
          span {
            background: white;
          }
        }
      }
    }
  }

  .luf-navicon {

    width: em-calc(20);
    height: em-calc(20);

    @include media-breakpoint-up($mainnav_breakpoint) {
      width: em-calc(12);
      height: em-calc(12);
    }

    span {
      height: em-calc(2);
    }
  }
}