.luf {
  &-scrollnavbar {
    position: relative;
    display: flex;
    margin-left: -8%;
    margin-right: -8%;
    flex: 1 1 100%;
    background-color: $white;

    &:before, &:after {
      content: '';
      position: absolute;
      background: rgba($white,1);
      top:0;
      height: 100%;
      width: 15%;
      pointer-events: none;
      z-index: 2;
    }

    &:before {
      left: 0;
      width: 7%;
      background: linear-gradient(-90deg, rgba($white,0) 0%, rgba($white,1) 50%);
    }
    &:after {
      right: 0;
      background: linear-gradient(90deg, rgba($white,0) 0%, rgba($white,1) 65%);
    }

    &--wrapper {
      position: relative;
      background-color: $white;
      z-index: 99;
    }


    & &__container {
      position: relative;
      overflow-y: hidden;
      height: rem-calc(56);
      width: 100%;

    }

    & &__scroll {
      white-space: nowrap;
      overflow-x: scroll;
      position: absolute;
      left:0;
      right: 0;
      padding-bottom: rem-calc(40);
    }
    &__scrollitem {
      display: inline-block;
      margin-right: rem-calc(71);
      margin-right: 5.5%;
      padding: 0.75rem 0;
      color: $body-color;

      &:active, &:focus {
        outline: none;
        color: $link-color;
      }

      &:first-child {
        margin-left: 7%;

      }
      &:last-child {
        margin-right: 12%;
      }
    }
  }
}
