.luf-m16 {
  padding-bottom: rem-calc(34);
  padding-top: rem-calc(34);

  @include media-breakpoint-up(md) {
    padding-bottom: rem-calc(68);
    //padding-bottom: 0;
    padding-top: rem-calc(68);
  }
  &--header {

    @include media-breakpoint-up(md) {
      padding-right: $grid-gutter-width * 2;
    }
  }
  &--stickyheader {
    position: relative;
    line-height: 1.3;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    width: 100%;
    color: white;

    @include media-breakpoint-up(sm) {

    }

    &--inner {
      background-color: $primary;
      padding-top: rem-calc(11);
      padding-bottom: rem-calc(9);
      display: none;
    }

    .btn-primary {
    }
  }


  .luf-m7--details {
    padding-top: rem-calc(15);
    line-height: 1.2;
  }
  &--date {
    display: flex;
    &__title {
      margin-right: rem-calc(18);
    }
  }
  &--tags {
    margin-bottom: rem-calc(20);
    li {
      display: inline-block;

      a {
        color: $body-color;
        box-shadow: 0 1px 0 0 $link-color;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray-400;
        padding-right: 0.3rem;
      }
    }
  }
  &--badges {
    font-size: rem-calc(60);
    li {
      display: inline-block;
      .luf-icon {
        display: block;
        margin-right: 0;
      }
    }
  }

  .luf-flag {
    margin-left: -20px;
  }

  .luf-img--copyright {
    right: initial;
    top: 0;
    bottom: initial;
  }

}

//sticky header
.luf-m16-sticky {
  position: relative;
  z-index: 15;

  &.is_stuck {

    .luf-scrollnavbar--wrapper {

    }

    .luf-m16--stickyheader {
      @include media-breakpoint-up(sm) {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;

        .luf-m16--stickyheader--inner {
          display: block;
        }
      }
    }

  }
}