.luf-m5 {

  text-align: center;
  font-size: rem-calc(14);

  @include media-breakpoint-up(sm) {
    font-size: inherit;
  }


  p {
    line-height: 1.3;
  }

  & &--icon {
    font-size: rem-calc(45);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(14);

    @include media-breakpoint-up(md) {
      margin-bottom: rem-calc(28);
      font-size: rem-calc(85);
    }

    .luf-icon {
      display: block;
      margin: auto;
      width: 2em;
    }
  }
  & &--grid {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  & &--content {
    max-width: rem-calc(232);
    margin-right: auto;
    margin-left: auto;
  }
  & &--item {
    position: relative;
    padding: 2rem;
    border-bottom: 1px solid $gray-400;

    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    p {
      margin: 0;
    }

    //add border and visual spacer
    &:nth-child(n) {
      border-right: 1px solid $gray-400;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom:-1rem;
        right:-2rem;
        width: 4rem;
        height: 2rem;
        background-color: $white;
        z-index: 1;

        @include media-breakpoint-up(md) {
          bottom:-1.5rem;
          right:-3.5rem;
          width: 7rem;
          height: 3rem;
        }
      }

    }

    //style 2n child for mobile and desktop
    &:nth-child(2n) {
      border-right: 1px solid transparent;

      &:after {
        background-color: transparent;

        @include media-breakpoint-up(md) {
          background-color:$white;
        }
      }
      @include media-breakpoint-up(md) {
        border-right: 1px solid $gray-400;
      }
    }

    //on desktop sho no border for every third element
    &:nth-child(3n) {
      @include media-breakpoint-up(md) {
        border-right: 1px solid transparent;
      }
    }

    //remove border from last third item if is three grid
    &:nth-last-child(-n+3) {

      @include media-breakpoint-up(md) {
        border-bottom: 1px solid transparent;
      }

      &:after {
        @include media-breakpoint-up(md) {
          background-color: transparent;
        }
      }

    }

    //remove border from last two items
    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }
  }
}