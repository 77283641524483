.luf-footer {
  background-color: $gray;
  line-height: 2;

  &--subtitle {
    margin-bottom: rem-calc(17);
  }

  &--top {
    padding-top: rem-calc(44);
    padding-bottom: rem-calc(24);
  }
  &--trimline {
    width: 100%;
    height: 1px;
    background-color: $white;
  }
  &--sub {
    padding-top: rem-calc(12);
    padding-bottom: rem-calc(22);

    .luf-footer {

      &--nav {
        &:first-child {
          @include media-breakpoint-up(xs) {
            column-count: 2;
          }
          @include media-breakpoint-up(md) {
            column-count: 1;
          }
        }
      }
      &--navitem {
        display: block;

        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-right: rem-calc(31);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .luf-seit-badge, .luf-traditions-badge {
    width: auto;
    position: relative;
    margin-right: 20px;

    @include media-breakpoint-up(md) {
      position: absolute;
      margin-right: 0px;
      right: 0
    }
  }

  .luf-traditions-badge {
    bottom: 0px;

    @include media-breakpoint-up(md) {
      bottom: 50px;
    }
  }

  p {
    margin: 0;
    line-height: 2;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
