html {
  font-size: 100%;
}

a {
  transition-duration: 0.3s;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
    border-radius: 2px;
  }
}

p {
  line-height: 1.8;
}

blockquote {
  display: inline-block;
  position: relative;
  margin: 0;

  p {
    &:first-child {
      font-style: italic;

      &:before {
        top: 15px;
        left: 20px;
        content: '\201c';
      }

      &:after {
        bottom: -10px;
        right: 20px;
        content: '\201e';
      }
    }
  }
}

.luf-ul-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.luf-toc {
  margin: 0;
  padding: 0 !important;
  list-style: none;

  li {
    display: flex;

    &.luf-toc--sep {
      margin-top: 1rem;
    }

    &:not(.luf-toc--sep):after {
      content: "";
      border-bottom: 1px dotted;
      flex-grow: 1;
      order: 2;
    }
  }
  &--title {
    order: 1;
  }
  &--details {
    order: 3;
  }

}

/*****************************
- global font classes
 ******************************/

// font sizes

//~60px

@mixin luf-ft-s5 {
  font-size: rem-calc(60);
  @include fluid-type(font-size, $min-vw, $max-vw, 2*$min-font, 3.75*$min-font);

}
.luf-ft-s5 {
  @include luf-ft-s5;
}

//~40px

@mixin luf-ft-s4 {
  font-size: rem-calc(40);
  @include fluid-type(font-size, $min-vw, $max-vw, 1.5*$min-font, 2.5*$min-font);

}
.luf-ft-s4 {
  @include luf-ft-s4;
}

//~30px
@mixin luf-ft-s3 {
  font-size: rem-calc(30);
  @include fluid-type(font-size, $min-vw, $max-vw, 1.25*$min-font, 1.875*$min-font);
}

.luf-ft-s3 {
  @include luf-ft-s3;
}

//~24px
@mixin luf-ft-s2 {
  font-size: rem-calc(24);
  @include fluid-type(font-size, $min-vw, $max-vw, 1.2*$min-font, 1.5*$min-font);
}

.luf-ft-s2 {
  @include luf-ft-s2;
}


//~20px

@mixin luf-ft-s1 {
  font-size: rem-calc(20);
}
.luf-ft-s1 {
  @include luf-ft-s1;
}

//~16px
@mixin luf-ft-sn {
  font-size: 1rem;
}

.luf-ft-sn {
  @include luf-ft-sn;
}

//~14px

@mixin luf-ft-s-1 {
  font-size: rem-calc(14);
}

.luf-ft-s-1, small {
  @include luf-ft-s-1;
}

//~12px
@mixin luf-ft-s-2 {
  font-size: rem-calc(12);
}
.luf-ft-s-2, small {
  @include luf-ft-s-2;
}

//~10
@mixin luf-ft-s-3 {
  font-size: rem-calc(10);
}
.luf-ft-s-3 {
  @include luf-ft-s-3;
}


// Weights
.luf-ft-w-900 {
  font-weight: 900;
}

.luf-ft-w-700, strong {
  font-weight: 700;
}

.luf-ft-w-500 {
  font-weight: 500;
}

.luf-ft-w-400 {
  font-weight: 400;
}

.luf-ft-w-300 {
  font-weight: 300;
}

.luf-ft-w-100 {
  font-weight: 100;
}

// Line-Heihgts

.luf-ft-lh-small {
  line-height: 1.1;
}

.luf-ft-lh-medium {
  line-height: 1.5;
  vertical-align: middle;
}

//Typo

.luf-handwriting {
  font-family: 'HandTest','Roboto', sans-serif;
}

// Transforms
.luf-ft-t-uc {
  text-transform: uppercase;
}

.luf-ft-t-it {
  font-style: italic;
}

@mixin luf-hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.luf-hide {
  @include luf-hide;
}

@mixin luf-visible {
  position: relative !important;
  clip: auto;
  overflow: visible;
  width: auto;
  height: auto;
}

.luf-visible {
  @include luf-visible;
}

//columns
.luf-col-two {

  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: 3rem;
  }
}

//colors
.luf-ft-white {
  color: $white;
}

.luf-ft-black {
  color: $black;
}

.luf-ft-gray {
  color: $gray-500;
}

//links
.luf-link {
  position: relative;
  color: $black;
  box-shadow: 0 2px 0 $link-color;

  &:hover, &:focus {
    color: $link-color;
  }

  &--bold {
    @extend .luf-ft-w-700 !optional;
  }

  &--colored {
    @extend .luf-ft-w-700 !optional;
    color: $primary;

    &:hover, &:focus {
      color: darken($primary, 10%)
    }
  }
}

