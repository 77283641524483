.luf-m4 {


  & &--inner {
    max-width: rem-calc(750);
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    padding-left: 1rem;
  }
}