.luf-m13 {
  position: relative;

  &__right {
    .luf-m13--img {
      margin-right: 0;

      @include media-breakpoint-up(lg) {
        margin-left: calc(((100vw - (#{$container-max-width}))/2)*-1);
      }

    }
    .luf-m13--inner {
      margin-left: auto;
      margin-right: 0;
    }
  }

  [class*="col-"] {
    position: static;
  }
  &--img {
    @include media-breakpoint-up(lg) {
      margin-right: calc(((100vw - (#{$container-max-width}))/2)*-1);
    }
  }
  &--inner {
    max-width: rem-calc(390);
    margin-right: auto;
    margin-top: 2rem;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }

    p {
      margin: 0;
    }
     .btn {
        margin-top: 2rem;
      }

  }

  &--icon {
    width: rem-calc(24);
    margin-bottom: 0.5rem;
  }
  &--heading {
    margin-bottom: rem-calc(22);
  }
}