.luf-m11 {
  position: relative;

  &--slider {
    &--thumbs {
      position: relative;
      height: 100%;
      .slick-list, .slick-track {
        height: 100%;
      }
      .slick-slide {
        position: relative;
      }
    }
  }

  .luf-slider--control {
    position: absolute;
    bottom: 0.25rem;
    width: 120px;
    left: 49%;
    height: 6rem;

    .luf-slider--btn {
      padding: 1.5rem;
      border: 1px solid $gray-400;

      &:hover {
        border-color: $primary;
      }
    }
  }

  &--intro {
    [class*="col-"] {
      position: static;
    }
  }

  & &--heading {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(sm) {
      transform: translateY(200%);
    }
  }
  & &--content, & &--heading {
    width: 90%;
    max-width: rem-calc(407);
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }
  & &--inner {


    @include media-breakpoint-up(sm) {
      padding-top: rem-calc(150);
      padding-bottom: rem-calc(112);
    }
  }
  & &--img, .luf-lazy {
    padding-top: 74.81%;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }
  }
}