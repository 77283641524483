.lightpick {
  position: absolute;
  z-index: 99999;
  padding: 4px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  color: #000;
  line-height: 1.125em;

  @include media-breakpoint-down($mainnav_breakpoint) {
    left: 5% !important;
    width: 90%;
  }

}

.lightpick--inlined {
  position: relative;
  display: inline-block;
}

.lightpick,
.lightpick *,
.lightpick::after,
.lightpick::before {
  box-sizing: border-box;
}

.lightpick.is-hidden {
  display: none;
}

.lightpick__select {
  background: none;
  background-position: 100% 50%;
  width: auto;
  padding: 0 !important;

  &:disabled {
    background: none;
  }
}

.lightpick__months {
  display: grid;
  background-color: #EEE;
  grid-template-columns: auto;
  -ms-grid-columns: auto;
  grid-gap: 1px;
}

.lightpick--2-columns .lightpick__months {
  grid-template-columns: auto auto;
  -ms-grid-columns: auto;
}

.lightpick--3-columns .lightpick__months {
  grid-template-columns: auto auto auto;
}

.lightpick--4-columns .lightpick__months {
  grid-template-columns: auto auto auto auto;
}

.lightpick--5-columns .lightpick__months {
  grid-template-columns: auto auto auto auto auto;
}

.lightpick__month {
  padding: 4px;
  width: 288px;
  background-color: #FFF;
}

.lightpick__month-title-bar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
}

.lightpick__month-title {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: default;
  padding: 0 4px;
  border-radius: 4px;
}

.lightpick__month-title > .lightpick__select {
  border: none;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.lightpick__month-title > .lightpick__select:disabled {
  color: #333;
}

.lightpick__month-title > .lightpick__select-months {
  font-weight: bold;
  font-size: 1em;
  margin-right: .5em;
}

.lightpick__toolbar {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
  display: flex;
  margin-left: 6px;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: #DDD;
  justify-content: center;
  align-items: center;
}

.lightpick__previous-action,
.lightpick__next-action {
  font-size: 12px;
}

.lightpick__close-action {
  font-size: 18px;
}

.lightpick__previous-action:active,
.lightpick__next-action:active,
.lightpick__close-action:active {
  color: inherit;
}

.lightpick__days-of-the-week {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-weight: bold;
  margin-left: 4px;
}

.lightpick__day-of-the-week {
  display: block;
  width: 13.5%;
  flex: 1 0 13.5%;
  max-width: 13.5% !important;
  text-align:center;
  font-size: 11px;
}

.lightpick__days {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 4px;
  cursor: pointer;
}

.lightpick__day {
  display: block;
  width: 13.5%;
  flex: 1 0 13.5%;
  max-width: 13.5% !important;
  text-align:center;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.lightpick__day.is-today {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(220, 50, 47, .5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: 18.8% auto;
  background-position: center bottom;
  color: #DC322F;
  background-repeat: no-repeat;
}

.lightpick__day:not(.is-disabled):hover {
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23E0E0E0' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-disabled {
  opacity: 0.38;
  pointer-events: none;
  cursor: initial;
}

.lightpick__day.disabled-tooltip {
  pointer-events: auto;
}

.lightpick__day.is-disabled.is-forward-selected {
  opacity: 1;
}
.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-previous-month,
.lightpick__day.is-next-month {
  opacity: 0.38;
}

.lightpick__day.lightpick__day.is-in-range:not(.is-disabled) {
  opacity: 1;
}

.lightpick__day.is-in-range {
  border-radius: 0;
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-in-range:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(38, 139, 210, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 50%;
  background-color: #268BD2;
  background-image: none;
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
  border-top-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 0;
  background-color: #268BD2;
  background-image: none;
}

.lightpick__day.is-start-date.is-end-date {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: auto;
  background-position: center;
  color: #FFF;
  font-weight: bold;
}

.lightpick__tooltip {
  position: absolute;
  margin-top: -4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
}

.lightpick__tooltip::before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}

.lightpick__tooltip::after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.lightpick__footer {
  display: flex;
  justify-content: space-between;
}
.lightpick__reset-action,
.lightpick__apply-action {
  border-radius: 5px;
  font-size: 12px;
  border: none;
}
.lightpick__reset-action {
  color: #fff;
  background-color: #aeacad;
}
.lightpick__apply-action {
  color: #fff;
  background-color: #2495f3;
}