.luf-m20 {
  margin-bottom: rem-calc(60);

  @include media-breakpoint-up(sm) {
    margin-bottom: rem-calc(80);
  }

  &--image {
    position: relative;

    &__icon {
      position: absolute;
      top:rem-calc(20);
      right: rem-calc(20);
      background-color: $primary;
      font-size: rem-calc(24);
      padding: 0.5rem;
      border-radius: rem-calc(2);

      .luf-icon {
        display: block;
        fill: $white;
        margin: 0;
      }
    }
  }

  &--title {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
  &--subline {
    line-height: 1;
    margin-bottom: rem-calc(17);

    @include media-breakpoint-up(sm) {
      margin-bottom: rem-calc(47);
    }
  }

  &.luf-bus-card {
    img {
      padding-top: 20px;
    }
  }

  .luf-img {
    .luf-img--holder {
      background-color: #f3f3f3;
    }
  }
}