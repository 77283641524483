.luf-m10 {
  position: relative;

  [class*="col-"] {
    position: static;
  }

  &--img {
    position: relative;
    height: 100%;
  }

  &--slider {
    &--slides {
      box-shadow: -40px 0 20px -20px rgba($black,0.2);

      @include media-breakpoint-up(lg) {
        box-shadow: none;
      }
    }
   &--thumbs {
     position: relative;
     height: 100%;
     .slick-list, .slick-track {
       height: 100%;
       min-height: 800px;
       @include media-breakpoint-up(sm) {
         min-height: auto;
       }
       @media only screen
       and (device-width : 414px)
       and (device-height : 896px)
       and (-webkit-device-pixel-ratio : 2) {
         min-height: 800px;
       }
     }
     .slick-slide {
       position: relative;
     }
   }
  }

  & &--heading {
    margin-bottom: rem-calc(38);
  }
  & &--inner {
    width: 80%;
    max-width: rem-calc(397);
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
      width: auto;
      padding-top: rem-calc(76);
      padding-bottom: rem-calc(95);
    }
  }

  & &--content {
    background-color: $white;

    .luf-m7 {
      &--title {
        margin-bottom: rem-calc(16);
      }
      &--content {
        padding: 0;
      }
      &--details {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @include media-breakpoint-up(xs) {
          display: flex;
        }
      }
      &--footer__cta {
        margin-right: rem-calc(30);
      }
    }
  }
}