$ease-in-out: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);

$ease-in-out-alt: ease-in-out;
$ease-in-alt: ease-in;
$ease-out-alt: ease-out;

//****************************************************
//colors
//*****************************************************


$gray-100: #F0F0F0;
$gray-200: #e9ecef;
$gray-300: #e7e7e7;
$gray-400: #D8D8D8;
$gray-500: #979797;
$gray-600: #666666;
$gray-700: #484848;
$gray-800: #333333;
$gray-900: #12120e;

$gray:  #e7e7e7;
$white: #fff;
$black: #353334;


$primary:         #0095d9; //blue
$success:         $green;
$info:            $cyan;
$warning:         $yellow;
$danger:          $red;
$light:           $gray-100;
$dark:            $gray-800;

//theme colors
$ct-1:           #046ab0; //theme darkblue
$ct-2:           #b8a0c4; //theme lavendel
$ct-3:           #fbc60e; //theme yellow
$ct-4:           #93b13d; //theme green
$ct-5:           #d02128; //theme red
$ct-6:           #607d8b; //theme greenish


$body-bg:                   $white;
$body-color:                $black;


$theme-colors: ();
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $body-color,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":       $dark
                ),
                $theme-colors
);

$colors: ();


$border-radius:               .125rem;
$btn-border-radius:           $border-radius;

//****************************************************
// Links
// source: bootstrap variables
//*****************************************************

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration:  none;

//****************************************************
// BUTTONS
// source: bootstrap variables
//*****************************************************

$input-btn-padding-y:         .4625rem;
$input-btn-padding-x:         1.7375rem;

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;

//****************************************************
//forms
// source: bootstrap variables
//*****************************************************

$input-border-radius:          2px;
$input-border-radius-lg:       0;
$input-border-radius-sm:       0;
$input-padding-y:                       1.375rem;
$input-padding-x:                       1rem;
$input-height-inner: auto;
$input-bg: rgba($gray-300,0.7);
$input-border-color: rgba($gray-300,0.7);


//****************************************************
//main navigation
//*****************************************************

$mainnav_breakpoint: sm;

//****************************************************
//Layout
//*****************************************************


// Grid breakpoints
//
// source: bootstrap variables
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xxs: 0,
        xs: 540px,
        sm: 768px,
        md: 992px,
        lg: 1140px,
        xl: 1400px
);

// Grid containers
//
// source: bootstrap variables
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        xs: 570px,
        sm: 828px,
        md: 1052px,
        lg: 1170px
);

$grid-gutter-width: 30px;
$container-max-width: 1140px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.875rem; //grid gutter width in rem
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: $spacer,
                        2: ($spacer * .25),
                        3: ($spacer * .5),
                        4: ($spacer * .75),
                        5: ($spacer * 1.25),
                        6: ($spacer * 1.5),
                        7: ($spacer * 2),
                        8: ($spacer * 3),
                        9: ($spacer * 6)
                ),
                $spacers
);

//****************************************************
//fonts
//*****************************************************

$font-family-sans-serif:      'Roboto', sans-serif;
$font-family-base:            $font-family-sans-serif;
$line-height-base:            1.6;

$font-size-default:           16px;
$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$headings-font-weight:        $font-weight-normal;

$min-vw: 320px;
$max-vw: $container-max-width;
$min-font: $font-size-default;
$max-font: 100px;

//modal
$modal-content-border-radius:       0;
$modal-xl:                          $container-max-width - 400;
$modal-lg:                          80%;
$modal-md:                          80%;
$modal-sm:                          300px;

//header
$fel-header-height-mob: 40px;
$fel-header-height: 56px;

//video
$plyr-color-main: $primary;
$plyr-color-fiord: $primary;
$plyr-menu-color: $primary;
$plyr-range-track-height: 6px;

//slider
$slick-opacity-default: 1;
$slick-dot-color: $white;
$slick-dot-color-active: $slick-dot-color;

// dropdown
$dropdown-border-radius: 2px;
