.luf-share {
  padding-top: 1.875rem;
  &--list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: rem-calc(32);

    .luf-icon__inline {
      margin: 0;
    }
    li {
      display: inline-block;
      margin-right: rem-calc(24);
    }
  }
}