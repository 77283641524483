@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}*/

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* no slide */

.slick-no-slide .slick-track {
    width: 100% !important ;
    text-align: center !important;
    transform: inherit !important;
}
.slick-no-slide .slick-slide {
    float: none !important;
    display: inline-block !important;
}

/* Arrows */

//btns next prev

.luf-slider--btn {
    color: $primary;
    display: block;
    position: absolute;
    top:50%;
    background-color: $white;
    border: 1px solid $gray-400;
    border-radius: 0;
    margin: 0;
    padding: 3em 1.8em;
    outline: none;
    opacity: 1;
    z-index: 99;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;

    &:hover, &:focus {
        outline: none;
        border-color: $primary;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    }

    .luf-slider--btn__arrow {
        @include arrow($size: 1.25em, $color: currentColor, $angle: 45, $thick: 2, $direction: -90, $bright: 22%, $aleft: 22%)
    }

    &.luf-next {
        right:0;
        border-right-color: transparent;
        border-radius: 2px 0 0 2px;
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);

        .luf-slider--btn__arrow {
            right: 25%;
        }

        &:hover {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        }
    }

    &.luf-prev {
        left:0;
        border-left-color: transparent;
        border-radius: 0 2px 2px 0;
        box-shadow: -3px 5px 10px rgba(0, 0, 0, 0.1);

        .luf-slider--btn__arrow {
            left: 20%;
            transform: rotate(-270deg) translateX(-50%) translateY(-50%)
        }

        &:hover {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        }
    }

    &.slick-disabled {
        cursor: default;
        color: darken($gray-100, 15%);
        opacity: 0;
    }
}

.slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: rem-calc(60);
}

.slick-dots {
    position: absolute;
    bottom: -#{rem-calc(20)};
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
        bottom: -#{rem-calc(40)};
    }
    li {
        position: relative;
        display: inline-block;
        margin-right: rem-calc(15);
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: rem-calc(4);
            width: rem-calc(40);
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;
            background-color: $slick-dot-color;
            opacity: $slick-opacity-not-active;
            transition: $ease-in-out;
            transition-duration: 250ms;

            &:hover, &:focus {
                outline: none;
                opacity: $slick-opacity-on-hover;
            }
        }
        &.slick-active button {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
