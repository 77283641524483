.flipbook-viewer {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #BBD2E6;
  color: #fff;
}

.flipbook-header {
  background: #4072A2;
  background: url(../images/flipbook-viewer/header.jpg) center center repeat;
  height: 40px;
}

.flipbook-header-logo {
  float: left;
  position: relative;
  top: 10px;
}

.flipbook-header-logo img {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  height: 20px;
  opacity: 0.5;
}

.flipbook-header-back-link {
  float: right;
  position: relative;
  top: 11px;
  font-size: 10px;
  font-weight: 700;
}
.flipbook-header-back-link a {
  color: #fff;
  text-decoration: underline;
}
.flipbook-header-back-link a:hover {
  color: #fff;
  text-decoration: none;
}

.flipbook-container {
  width: 1000px;
  margin: auto;
}

.flipbook-wrapper {
  margin: 40px 0;
}

.flipbook {
  display: none;
}

.flipbook.loaded {
  display: block;
  cursor: zoom-in;
}
.flipbook.zoom-in {
  cursor: zoom-out;
}

.flipbook-title-wrap {
  margin-bottom: 15px;
}

.flipbook-title {
  font-size: 22px;
  font-weight: 300;
  margin: 0;
  float: left;
}
.flipbook-title strong {
  font-weight: 700;
  text-transform: uppercase;
}

.search-input-wrapper {
  position: relative;
  float: right;
  width: 160px;
  background: #A3C2DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.search-input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  text-align: right;
  padding: 2px 10px;
  padding-right: 30px;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
}
.search-input::-webkit-input-placeholder {
  color: #fff;
}
.search-input::-moz-input-placeholder {
  color: #fff;
}
.search-input::input-placeholder {
  color: #fff;
}

.search-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  width: 14px;
  height: auto;
}

.flipbook-content {
  position: relative;
}

.flipbook-actions {
  position: absolute;
  top: 0;
  right: -45px;
}

.flipbook-action,
.flipbook-action:hover,
.flipbook-action:focus {
  cursor: pointer;
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background: #0095D9;
  box-shadow: 1px 2px 3px rgba(53, 51, 52, 0.4);
  margin-bottom: 10px;
}
.flipbook-action img {
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.flipbook-action-download img {
  width: 13px;
}
.flipbook-action-mail img {
  width: 17px;
}
.flipbook-action-print img {
  width: 15px;
}

.flipbook-nav-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0.5;
  -webkit-transition: opacity 340ms ease;
  -moz-transition: opacity 340ms ease;
  transition: opacity 340ms ease;
}
.flipbook-nav-arrow:hover {
  opacity: 1;
}
.flipbook-nav-arrow.disabled {
  opacity: 0;
}
.flipbook-nav-arrow-left {
  left: -67px;
}
.flipbook-nav-arrow-right {
  right: -67px;
}

.flipbook-footer {
  position: absolute;
  bottom: -40px;
  right: 0;
  font-size: 12px;
}

.flipbook-page-indicator {
  float: left;
}

.flipbook-page-indicator-text {
  font-size: 12px;
  font-weight: 300;
  margin-right: 10px;
  float: left;
}

.flipbook-action-overview {
  cursor: pointer;
  float: left;
  padding: 5px;
  margin-top: -5px;
}

.flipbook-action-zoom {
  cursor: pointer;
  float: left;
  padding: 5px;
  margin-top: -5px;
  margin-right: -5px;
}

.flipbook-page-indicator-input-wrapper {
  position: relative;
  top: -3px;
  float: right;
  width: auto;
  background: #A3C2DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 5px;
}
.flipbook-page-indicator-input-form {
  display: block;
  float: left;
}
.flipbook-page-indicator-input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  text-align: right;
  padding: 3px 0;
  font-size: 12px;
  font-weight: 300;
  width: 50px;
  float: left;
  font-weight: 700;
}
.flipbook-page-indicator-input-after {
  float: left;
  position: relative;
  top: 3px;
  padding-left: 5px;
  padding-right: 10px;
  font-weight: 300;
}



.flipbook-animated {
  -webkit-transition: margin-left 0.5s;
  -moz-transition: margin-left 0.5s;
  -ms-transition: margin-left 0.5s;
  -o-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.esc-notice {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 10000;
}
.esc-notice > div {
  width: 140px;
  height: 30px;
  margin: auto;
  background: rgba(0,0,0,0.5);
  text-align: center;
  font: 12px arial;
  line-height: 30px;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.small-page {
  position: relative;
}

.large-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flipbook-content {
  .small-page {
    height: 100%;
  }
}

.flipbook-thumbnails {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 160px;
  overflow: auto;
  background: #4E6270;
  box-shadow: 0 0 4px rgba(0,0,0,0.17);
  left: -160px;
  -webkit-transition: left 300ms ease;
  -moz-transition: left 300ms ease;
  transition: left 300ms ease;
}
.flipbook-thumbnails.visible {
  left: 0;
}
.flipbook-thumbnails-inner {
  padding: 20px;
  position: relative;
}
.flipbook-thumbnail {
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
  opacity: 0.7;
  -webkit-transition: opacity 340ms ease;
  -moz-transition: opacity 340ms ease;
  transition: opacity 340ms ease;
}
.flipbook-thumbnail.active,
.flipbook-thumbnail:hover {
  opacity: 1;
}
.page-right {
  width: 50%;
  float: right;
}
.page-left {
  width: 50%;
  float: left;
}

.page-number-indicator {
  position: absolute;
  top: 10px;
  left: -5px;
  padding: 2px 5px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0,0,0,0.17);
  color: #000;
  font-size: 10px;
}
.flipbook-thumbnail[data-page="1"] .page-number-indicator {
  left: auto;
  right: -5px;
}

.flipbook-search {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  overflow: auto;
  background: #4E6270;
  box-shadow: 0 0 4px rgba(0,0,0,0.17);
  left: -320px;
  -webkit-transition: left 300ms ease;
  -moz-transition: left 300ms ease;
  transition: left 300ms ease;
}
.flipbook-search.visible {
  left: 0;
}
.flipbook-search-inner {
  padding: 20px;
  position: relative;
}
.flipbook-search-entry {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #a3c2dc;
  margin: 0 -20px;
  padding: 20px;
}
.flipbook-search-entry:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.flipbook-search-entry-image {
  float: left;
  position: relative;
  width: 25%;
}
.flipbook-search-entry-content {
  width: 70%;
  float: right;
}
.flipbook-search-entry-title {
  font-weight: 700;
  margin-bottom: 0.5em;
  font-size: 14px;
}
.flipbook-search-entry-text {
  font-size: 12px;
}
.flipbook-search-loading-indicator {
  position: relative;
  padding-top: 100px;
  display: none;
}
.flipbook-search-loading-indicator img {
  display: block;
  width: auto;
  height: auto;
  margin: auto;
}
.flipbook-search-loading-indicator.visible {
  display: block;
}
.flipbook-search-loading-indicator.visible + .flipbook-search-inner {
  display: none;
}


.flipbook-search-title {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.5em;
}

.flipbook-search-noentries {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}


.mobile-only {
  display: none;
}

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.page-scrollbar {
  position: absolute;
  bottom: -28px;
  left: 0;
  height: 20px;
  width: 100%;
}
.page-scrollbar-background {
  position: relative;
  top: 7px;
  background: #A3C2DC;
  height: 6px;
  border-radius: 4px;
}
.page-scrollbar-fill {
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 4px;
  width: 50%;
  background: #fff;
  height: 6px;
}
.page-scrollbar-handle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}
.page-scrollbar-handle-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 12px;
}

@media screen and (max-width: 900px) {
  html,
  body {
    &.flipbook-body {
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
      height: -webkit-fill-available;
    }
  }

  .mobile-only {
    display: block;
  }

  .flipbook-header {
    background: none;
    height: auto;
  }
  .flipbook-container {
    width: auto !important;
    padding: 0 10px;
  }
  .flipbook-header-logo {
    display: none !important;
  }
  .flipbook-header-back-link {
    float: none;
    padding: 10px;
    top: auto;
  }
  .flipbook-header-back-link a {
    color: #0095D9;
  }
  .flipbook-wrapper {
    margin: 0;
  }
  .flipbook-title-wrap {
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .flipbook-title-wrap .search-input-wrapper {
    display: none;
  }
  .flipbook-title {
    font-size: 20px;
  }

  .flipbook-nav-arrow,
  .flipbook-footer {
    bottom: -55px;
  }

  .flipbook-nav-arrow {
    top: auto;
    opacity: 1;
  }
  .flipbook-nav-arrow img {
    height: 20px;
    width: auto;
  }
  .flipbook-nav-arrow-left {
    left: 50%;
    right: auto;
    transform: translate(-42px, 0);
  }
  .flipbook-nav-arrow-right {
    left: 50%;
    right: auto;
    transform: translate(70px, 0);
  }
  .flipbook-actions {
    display: none !important;
  }
  .flipbook-footer {
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .flipbook-page-indicator-text {
    display: none !important;
  }
  .flipbook-page-indicator-input-form {
    display: none !important;
  }
  .flipbook-page-indicator-input-wrapper {
    margin-right: 0;
    width: auto;
    background: none;
    top: -2px;
  }
  .flipbook-page-indicator-input-after {
    padding: 0;
    top: auto;
  }
  .flipbook-page-indicator-input-before {
    display: inline-block;
    font-weight: 700;
  }
  .flipbook-action-zoom {
    display: none !important;
  }
  .flipbook-action-overview {
    position: absolute;
    margin: 0;
    left: 50%;
    margin-left: 30px;
    top: -7px;
  }
  .flipbook-action-overview img {
    width: 18px;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .flipbook {
    display: none !important;
  }
  .flipbook-slider {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .flipbook-slider-page {
    float: left;
    height: 100%;
    width: 100%;
  }
  .flipbook-slider-page-image {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .flipbook-slider-page-image img {
    display: none;
    pointer-events: none;
  }
  .flipbook-slider-page.current .flipbook-slider-page-image img {
    display: block;
  }
  .flipbook-slider-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .flipbook-slider-mover {
    position: relative;
    height: 100%;
  }
  .flipbook-slider-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .flipbook-slider-page-image.animate {
    -webkit-transition: transform 500ms ease;
    -moz-transition: transform 500ms ease;
    -ms-transition: transform 500ms ease;
    -o-transition: transform 500ms ease;
    transition: transform 500ms ease;
  }


  .flipbook-title-wrap {
    position: relative;
  }
  .menu-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 -10px;
  }
  .menu-icon .dot {
    width: 8px;
    height: 8px;
    background: #0095D9;
    border-radius: 8px;
    margin-top: 3px;
  }
  .menu-icon .dot:first-child {
    margin-top: 0;
  }


  .flipbook-menu {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(67, 87, 100, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: opacity 340ms ease;
  }
  .flipbook-menu.visible {
    opacity: 1;
    pointer-events: auto;
  }
  .flipbook-menu .flipbook-title-wrap {
    padding-left: 20px;
    position: relative;
  }
  .flipbook-menu .close-icon {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 10px;
    transform: translate(0, -50%);
  }
  .flipbook-menu .close-icon img {
    width: 19px;
    height: auto;
    display: block;
    margin: 0;
  }
  .flipbook-menu-inner {
    padding: 30px 20px;
    position: relative;
  }
  .flipbook-menu .search-input-wrapper {
    float: none;
    width: auto;
    background: rgba(255, 255, 255, 0.3);
  }
  .flipbook-menu .search-input {
    font-size: 16px;
    padding: 7px 38px 7px 10px;
  }
  .flipbook-menu .search-icon {
    width: 19px;
    top: 10px;
  }
  .flipbook-menu .flipbook-actions {
    display: block !important;
    position: relative;
    right: auto;
    padding-top: 16px;
  }
  .flipbook-menu .flipbook-action {
    width: 86px;
    height: 86px;
    border-radius: 86px;
    margin: auto;
    margin-top: 24px;
  }
  .flipbook-menu .flipbook-action-download img {
    width: 31px;
  }
  .flipbook-menu .flipbook-action-mail img {
    width: 41px;
  }
  .flipbook-menu .flipbook-action-print img {
    width: 37px;
  }

  .flipbook-search {
    width: 100%;
    left: 0;
    top: auto;
    bottom: -100vh;
    height: calc(100vh - 190px);
    transition: bottom .3s ease;
    overflow: visible;
  }
  .flipbook-search.visible {
    bottom: 0;
  }
  .flipbook-search-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
  }
}
