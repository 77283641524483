.luf-m2 {
  position: relative;
  height: auto;
  overflow: hidden;
  & &--bg {
    background-position-y: bottom;
    background-position-x: center;
  }
  & &--content {
    max-width: rem-calc(652);
    margin-left: auto;
    margin-right: auto;
  }
  & &--inner {
    position: relative;
    z-index: 3;
    padding-top: rem-calc(341);
    padding-bottom: rem-calc(68);
  }
}