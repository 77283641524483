
.luf-m9 {

  & &--heading {
    margin-bottom: rem-calc(49);

    @include media-breakpoint-up(sm) {
      margin-bottom: rem-calc(98);
    }
  }

  & &--item {
    margin-bottom: rem-calc(30);

    @include media-breakpoint-up(sm) {
      margin-bottom: rem-calc(60);
    }

    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
      margin-bottom: 0;
    }
  }
  & &--icon {
    font-size: rem-calc(64);
    margin-top: 0.5rem;

    @include media-breakpoint-up(xs) {
      font-size: rem-calc(80);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(119);
    }


    .luf-icon {
      display: block;
      margin: 0 auto;
    }
  }
  & &--title {

    margin-bottom: rem-calc(10);
  }
}
