.luf-flag {
  position: relative;
  display: inline-block;
  color: $white;
  font-size: rem-calc(12);
  transform: rotate(-10deg);
  padding: 0.2rem;
  margin-bottom: 1rem;

  &--small {
    position: absolute;
    height: 100%;
    width: rem-calc(14);
    transform: rotate(10deg) skewY(30deg);
    top: rem-calc(7);
    right: calc(100% - 13px);
    overflow: hidden;
    background: darken($black,10%);
  }
  &--inner {
    background-color: $black;
  }
  &--bg {
    position: absolute;
    width: 95%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $black;
    transform: skewX(-10deg);
    transition-delay: 300ms;
    transition: width 600ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &--text {
    position: relative;
    z-index: 9;
    display: inline-block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}