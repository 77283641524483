@charset "UTF-8";
/* fluid typo */
html {
  font-size: 100%;
}

a {
  transition-duration: 0.3s;
}
a:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 149, 217, 0.5);
  border-radius: 2px;
}

p {
  line-height: 1.8;
}

blockquote {
  display: inline-block;
  position: relative;
  margin: 0;
}
blockquote p:first-child {
  font-style: italic;
}
blockquote p:first-child:before {
  top: 15px;
  left: 20px;
  content: "“";
}
blockquote p:first-child:after {
  bottom: -10px;
  right: 20px;
  content: "„";
}

.luf-ul-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.luf-toc {
  margin: 0;
  padding: 0 !important;
  list-style: none;
}
.luf-toc li {
  display: flex;
}
.luf-toc li.luf-toc--sep {
  margin-top: 1rem;
}
.luf-toc li:not(.luf-toc--sep):after {
  content: "";
  border-bottom: 1px dotted;
  flex-grow: 1;
  order: 2;
}
.luf-toc--title {
  order: 1;
}
.luf-toc--details {
  order: 3;
}

/*****************************
- global font classes
 ******************************/
.luf-ft-s5 {
  font-size: 3.75rem;
  font-size: 32px;
}
@media (min-width: 320px) {
  .luf-ft-s5 {
    font-size: calc(32px + 28 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s5 {
    font-size: 60px;
  }
}

.luf-ft-s4 {
  font-size: 2.5rem;
  font-size: 24px;
}
@media (min-width: 320px) {
  .luf-ft-s4 {
    font-size: calc(24px + 16 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s4 {
    font-size: 40px;
  }
}

.luf-ft-s3 {
  font-size: 1.875rem;
  font-size: 20px;
}
@media (min-width: 320px) {
  .luf-ft-s3 {
    font-size: calc(20px + 10 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s3 {
    font-size: 30px;
  }
}

.luf-ft-s2 {
  font-size: 1.5rem;
  font-size: 19.2px;
}
@media (min-width: 320px) {
  .luf-ft-s2 {
    font-size: calc(19.2px + 4.8 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .luf-ft-s2 {
    font-size: 24px;
  }
}

.luf-ft-s1 {
  font-size: 1.25rem;
}

.luf-ft-sn {
  font-size: 1rem;
}

.luf-ft-s-1, small {
  font-size: 0.875rem;
}

.luf-ft-s-2, small {
  font-size: 0.75rem;
}

.luf-ft-s-3 {
  font-size: 0.625rem;
}

.luf-ft-w-900 {
  font-weight: 900;
}

.luf-ft-w-700, .luf-link--colored, .luf-link--bold, strong {
  font-weight: 700;
}

.luf-ft-w-500 {
  font-weight: 500;
}

.luf-ft-w-400 {
  font-weight: 400;
}

.luf-ft-w-300 {
  font-weight: 300;
}

.luf-ft-w-100 {
  font-weight: 100;
}

.luf-ft-lh-small {
  line-height: 1.1;
}

.luf-ft-lh-medium {
  line-height: 1.5;
  vertical-align: middle;
}

.luf-handwriting {
  font-family: "HandTest", "Roboto", sans-serif;
}

.luf-ft-t-uc {
  text-transform: uppercase;
}

.luf-ft-t-it {
  font-style: italic;
}

.luf-hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.luf-visible {
  position: relative !important;
  clip: auto;
  overflow: visible;
  width: auto;
  height: auto;
}

@media (min-width: 992px) {
  .luf-col-two {
    column-count: 2;
    column-gap: 3rem;
  }
}

.luf-ft-white {
  color: #fff;
}

.luf-ft-black {
  color: #353334;
}

.luf-ft-gray {
  color: #979797;
}

.luf-link {
  position: relative;
  color: #353334;
  box-shadow: 0 2px 0 #0095d9;
}
.luf-link:hover, .luf-link:focus {
  color: #0095d9;
}
.luf-link--colored {
  color: #0095d9;
}
.luf-link--colored:hover, .luf-link--colored:focus {
  color: #0072a6;
}

.luf-footer {
  background-color: #e7e7e7;
  line-height: 2;
}
.luf-footer--subtitle {
  margin-bottom: 1.0625rem;
}
.luf-footer--top {
  padding-top: 2.75rem;
  padding-bottom: 1.5rem;
}
.luf-footer--trimline {
  width: 100%;
  height: 1px;
  background-color: #fff;
}
.luf-footer--sub {
  padding-top: 0.75rem;
  padding-bottom: 1.375rem;
}
@media (min-width: 540px) {
  .luf-footer--sub .luf-footer--nav:first-child {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .luf-footer--sub .luf-footer--nav:first-child {
    column-count: 1;
  }
}
.luf-footer--sub .luf-footer--navitem {
  display: block;
}
@media (min-width: 992px) {
  .luf-footer--sub .luf-footer--navitem {
    display: inline-block;
    margin-right: 1.9375rem;
  }
}
.luf-footer--sub .luf-footer--navitem:last-child {
  margin-right: 0;
}
.luf-footer .luf-seit-badge, .luf-footer .luf-traditions-badge {
  width: auto;
  position: relative;
  margin-right: 20px;
}
@media (min-width: 992px) {
  .luf-footer .luf-seit-badge, .luf-footer .luf-traditions-badge {
    position: absolute;
    margin-right: 0px;
    right: 0;
  }
}
.luf-footer .luf-traditions-badge {
  bottom: 0px;
}
@media (min-width: 992px) {
  .luf-footer .luf-traditions-badge {
    bottom: 50px;
  }
}
.luf-footer p {
  margin: 0;
  line-height: 2;
}
.luf-footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lightpick {
  position: absolute;
  z-index: 99999;
  padding: 4px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  color: #000;
  line-height: 1.125em;
}
@media (max-width: 991.98px) {
  .lightpick {
    left: 5% !important;
    width: 90%;
  }
}

.lightpick--inlined {
  position: relative;
  display: inline-block;
}

.lightpick,
.lightpick *,
.lightpick::after,
.lightpick::before {
  box-sizing: border-box;
}

.lightpick.is-hidden {
  display: none;
}

.lightpick__select {
  background: none;
  background-position: 100% 50%;
  width: auto;
  padding: 0 !important;
}
.lightpick__select:disabled {
  background: none;
}

.lightpick__months {
  display: grid;
  background-color: #EEE;
  grid-template-columns: auto;
  -ms-grid-columns: auto;
  grid-gap: 1px;
}

.lightpick--2-columns .lightpick__months {
  grid-template-columns: auto auto;
  -ms-grid-columns: auto;
}

.lightpick--3-columns .lightpick__months {
  grid-template-columns: auto auto auto;
}

.lightpick--4-columns .lightpick__months {
  grid-template-columns: auto auto auto auto;
}

.lightpick--5-columns .lightpick__months {
  grid-template-columns: auto auto auto auto auto;
}

.lightpick__month {
  padding: 4px;
  width: 288px;
  background-color: #FFF;
}

.lightpick__month-title-bar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
}

.lightpick__month-title {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: default;
  padding: 0 4px;
  border-radius: 4px;
}

.lightpick__month-title > .lightpick__select {
  border: none;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.lightpick__month-title > .lightpick__select:disabled {
  color: #333;
}

.lightpick__month-title > .lightpick__select-months {
  font-weight: bold;
  font-size: 1em;
  margin-right: 0.5em;
}

.lightpick__toolbar {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
  display: flex;
  margin-left: 6px;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: #DDD;
  justify-content: center;
  align-items: center;
}

.lightpick__previous-action,
.lightpick__next-action {
  font-size: 12px;
}

.lightpick__close-action {
  font-size: 18px;
}

.lightpick__previous-action:active,
.lightpick__next-action:active,
.lightpick__close-action:active {
  color: inherit;
}

.lightpick__days-of-the-week {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-weight: bold;
  margin-left: 4px;
}

.lightpick__day-of-the-week {
  display: block;
  width: 13.5%;
  flex: 1 0 13.5%;
  max-width: 13.5% !important;
  text-align: center;
  font-size: 11px;
}

.lightpick__days {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 4px;
  cursor: pointer;
}

.lightpick__day {
  display: block;
  width: 13.5%;
  flex: 1 0 13.5%;
  max-width: 13.5% !important;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.lightpick__day.is-today {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(220, 50, 47, .5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: 18.8% auto;
  background-position: center bottom;
  color: #DC322F;
  background-repeat: no-repeat;
}

.lightpick__day:not(.is-disabled):hover {
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23E0E0E0' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-disabled {
  opacity: 0.38;
  pointer-events: none;
  cursor: initial;
}

.lightpick__day.disabled-tooltip {
  pointer-events: auto;
}

.lightpick__day.is-disabled.is-forward-selected {
  opacity: 1;
}

.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-previous-month,
.lightpick__day.is-next-month {
  opacity: 0.38;
}

.lightpick__day.lightpick__day.is-in-range:not(.is-disabled) {
  opacity: 1;
}

.lightpick__day.is-in-range {
  border-radius: 0;
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-in-range:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(38, 139, 210, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 50%;
  background-color: #268BD2;
  background-image: none;
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
  border-top-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 0;
  background-color: #268BD2;
  background-image: none;
}

.lightpick__day.is-start-date.is-end-date {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23268BD2' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: auto;
  background-position: center;
  color: #FFF;
  font-weight: bold;
}

.lightpick__tooltip {
  position: absolute;
  margin-top: -4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
}

.lightpick__tooltip::before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}

.lightpick__tooltip::after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.lightpick__footer {
  display: flex;
  justify-content: space-between;
}

.lightpick__reset-action,
.lightpick__apply-action {
  border-radius: 5px;
  font-size: 12px;
  border: none;
}

.lightpick__reset-action {
  color: #fff;
  background-color: #aeacad;
}

.lightpick__apply-action {
  color: #fff;
  background-color: #2495f3;
}

.luf-container.accordion .card {
  border: none;
}
.luf-container.accordion .card .collapse.show {
  background-color: #f4f4f4;
}
.luf-container.accordion .card-header {
  padding: 1.5rem;
  min-height: 60px;
  background-color: #ffffff;
}
.luf-container.accordion .card-header .btn-primary {
  margin-top: 0.5rem;
}
@media (min-width: 540px) {
  .luf-container.accordion .card-header .btn-primary {
    margin-top: 0px;
  }
}
.luf-container.accordion .card-header .news-header-text {
  padding-top: 0.5rem;
}
.luf-container.accordion .pagination {
  list-style: none;
  display: block;
  text-align: center;
}
.luf-container.accordion .pagination li {
  line-height: 1;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  display: inline-block;
}
.luf-container.accordion .pagination li.active {
  background-color: #f4f4f4;
}
.luf-container.accordion .pagination li a, .luf-container.accordion .pagination li span {
  width: auto;
  height: auto;
  border-radius: 0;
  border: none;
  line-height: 1;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.luf-container.accordion .pagination li:hover {
  background-color: #f4f4f4;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}*/
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* no slide */
.slick-no-slide .slick-track {
  width: 100% !important;
  text-align: center !important;
  transform: inherit !important;
}

.slick-no-slide .slick-slide {
  float: none !important;
  display: inline-block !important;
}

/* Arrows */
.luf-slider--btn {
  color: #0095d9;
  display: block;
  position: absolute;
  top: 50%;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  border-radius: 0;
  margin: 0;
  padding: 3em 1.8em;
  outline: none;
  opacity: 1;
  z-index: 99;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
}
.luf-slider--btn:hover, .luf-slider--btn:focus {
  outline: none;
  border-color: #0095d9;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
.luf-slider--btn .luf-slider--btn__arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 50%;
  margin: auto;
  padding: 0;
  width: 1.25em;
  height: 1.25em;
  transform: rotate(-90deg) translateX(50%) translateY(-50%);
}
.luf-slider--btn .luf-slider--btn__arrow:before, .luf-slider--btn .luf-slider--btn__arrow:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  height: 78%;
  width: 0.125rem;
  background: currentColor;
  border-radius: 3px;
}
.luf-slider--btn .luf-slider--btn__arrow:before {
  right: 22%;
  transform: rotate(45deg);
  transform-origin: right;
}
.luf-slider--btn .luf-slider--btn__arrow:after {
  left: 22%;
  transform: rotate(-45deg);
  transform-origin: left;
}
.luf-slider--btn .luf-slider--btn__arrow.open:before {
  transform: rotate(-45deg);
}
.luf-slider--btn .luf-slider--btn__arrow.open:after {
  transform: rotate(45deg);
}
.luf-slider--btn.luf-next {
  right: 0;
  border-right-color: transparent;
  border-radius: 2px 0 0 2px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
}
.luf-slider--btn.luf-next .luf-slider--btn__arrow {
  right: 25%;
}
.luf-slider--btn.luf-next:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
.luf-slider--btn.luf-prev {
  left: 0;
  border-left-color: transparent;
  border-radius: 0 2px 2px 0;
  box-shadow: -3px 5px 10px rgba(0, 0, 0, 0.1);
}
.luf-slider--btn.luf-prev .luf-slider--btn__arrow {
  left: 20%;
  transform: rotate(-270deg) translateX(-50%) translateY(-50%);
}
.luf-slider--btn.luf-prev:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
.luf-slider--btn.slick-disabled {
  cursor: default;
  color: #cacaca;
  opacity: 0;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 3.75rem;
}

.slick-dots {
  position: absolute;
  bottom: -1.25rem;
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .slick-dots {
    bottom: -2.5rem;
  }
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin-right: 0.9375rem;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 0.25rem;
  width: 2.5rem;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
  background-color: #fff;
  opacity: 0.25;
  transition: cubic-bezier(0.86, 0, 0.07, 1);
  transition-duration: 250ms;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
  opacity: 1;
}
.slick-dots li.slick-active button {
  color: #fff;
  opacity: 1;
}

.luf-tag--category {
  display: inline-block;
  padding: 0 0.5625rem;
  margin-bottom: 0.3rem;
  background-color: #353334;
  color: #fff;
}

.luf-flag {
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 0.75rem;
  transform: rotate(-10deg);
  padding: 0.2rem;
  margin-bottom: 1rem;
}
.luf-flag--small {
  position: absolute;
  height: 100%;
  width: 0.875rem;
  transform: rotate(10deg) skewY(30deg);
  top: 0.4375rem;
  right: calc(100% - 13px);
  overflow: hidden;
  background: #1b1a1b;
}
.luf-flag--inner {
  background-color: #353334;
}
.luf-flag--bg {
  position: absolute;
  width: 95%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #353334;
  transform: skewX(-10deg);
  transition-delay: 300ms;
  transition: width 600ms;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.luf-flag--text {
  position: relative;
  z-index: 9;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.luf-gallery--slides {
  /* the parent */
}
.luf-gallery--slides .slick-slide {
  margin-left: 27px;
}
.luf-gallery--slides .slick-list {
  margin-left: -27px;
}
.luf-gallery--thumbs {
  /* the parent */
}
.luf-gallery--thumbs .slick-slide {
  margin-left: 10px;
}
.luf-gallery--thumbs .slick-list {
  margin-left: -10px;
}

.luf-pagination input {
  width: 2.9375rem;
  height: 2.9375rem;
  text-align: center;
}

.luf-share {
  padding-top: 1.875rem;
}
.luf-share--list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2rem;
}
.luf-share--list .luf-icon__inline {
  margin: 0;
}
.luf-share--list li {
  display: inline-block;
  margin-right: 1.5rem;
}

.luf-descriptionlist {
  font-size: 0.75rem;
  margin-top: 0.9375rem;
  margin-bottom: 1.875rem;
}
@media (min-width: 540px) {
  .luf-descriptionlist {
    font-size: 1rem;
  }
}
.luf-descriptionlist .luf-icon {
  margin-right: 0.875rem;
}
.luf-descriptionlist dl {
  display: flex;
  flex-flow: row wrap;
}
.luf-descriptionlist dt, .luf-descriptionlist dd {
  margin: 0;
  margin-bottom: 1rem;
  word-wrap: break-word;
  line-height: 1;
}
.luf-descriptionlist dt {
  width: 8.5rem;
  display: flex;
}
.luf-descriptionlist dd {
  width: calc(100% - 8.5rem - 8px);
}

.luf-scrollnavbar {
  position: relative;
  display: flex;
  margin-left: -8%;
  margin-right: -8%;
  flex: 1 1 100%;
  background-color: #fff;
}
.luf-scrollnavbar:before, .luf-scrollnavbar:after {
  content: "";
  position: absolute;
  background: white;
  top: 0;
  height: 100%;
  width: 15%;
  pointer-events: none;
  z-index: 2;
}
.luf-scrollnavbar:before {
  left: 0;
  width: 7%;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, white 50%);
}
.luf-scrollnavbar:after {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 65%);
}
.luf-scrollnavbar--wrapper {
  position: relative;
  background-color: #fff;
  z-index: 99;
}
.luf-scrollnavbar .luf-scrollnavbar__container {
  position: relative;
  overflow-y: hidden;
  height: 3.5rem;
  width: 100%;
}
.luf-scrollnavbar .luf-scrollnavbar__scroll {
  white-space: nowrap;
  overflow-x: scroll;
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 2.5rem;
}
.luf-scrollnavbar__scrollitem {
  display: inline-block;
  margin-right: 4.4375rem;
  margin-right: 5.5%;
  padding: 0.75rem 0;
  color: #353334;
}
.luf-scrollnavbar__scrollitem:active, .luf-scrollnavbar__scrollitem:focus {
  outline: none;
  color: #0095d9;
}
.luf-scrollnavbar__scrollitem:first-child {
  margin-left: 7%;
}
.luf-scrollnavbar__scrollitem:last-child {
  margin-right: 12%;
}

.luf-m2 {
  position: relative;
  height: auto;
  overflow: hidden;
}
.luf-m2 .luf-m2--bg {
  background-position-y: bottom;
  background-position-x: center;
}
.luf-m2 .luf-m2--content {
  max-width: 40.75rem;
  margin-left: auto;
  margin-right: auto;
}
.luf-m2 .luf-m2--inner {
  position: relative;
  z-index: 3;
  padding-top: 21.3125rem;
  padding-bottom: 4.25rem;
}

.luf-m3 {
  position: relative;
  height: 100%;
}
.luf-m3 .luf-icon {
  margin: 0;
  fill: currentColor;
}
.luf-m3 .luf-m3--inner {
  position: relative;
  z-index: 3;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  min-height: 600px;
  padding-top: 3.875rem;
  padding-bottom: 4.1875rem;
}
.luf-m3 .luf-m3--content {
  position: relative;
  width: 80%;
  max-width: 32.875rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
}
.luf-m3 .luf-m3--title__inner {
  display: block;
}

.luf-m4 .luf-m4--inner {
  max-width: 46.875rem;
  margin-left: auto;
  margin-right: auto;
}
.luf-m4 ul {
  padding-left: 1rem;
}

.luf-m5 {
  text-align: center;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .luf-m5 {
    font-size: inherit;
  }
}
.luf-m5 p {
  line-height: 1.3;
}
.luf-m5 .luf-m5--icon {
  font-size: 2.8125rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.875rem;
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--icon {
    margin-bottom: 1.75rem;
    font-size: 5.3125rem;
  }
}
.luf-m5 .luf-m5--icon .luf-icon {
  display: block;
  margin: auto;
  width: 2em;
}
.luf-m5 .luf-m5--grid {
  margin-top: 2rem;
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--grid {
    margin-top: 4rem;
  }
}
.luf-m5 .luf-m5--content {
  max-width: 14.5rem;
  margin-right: auto;
  margin-left: auto;
}
.luf-m5 .luf-m5--item {
  position: relative;
  padding: 2rem;
  border-bottom: 1px solid #D8D8D8;
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item {
    padding: 4rem;
  }
}
.luf-m5 .luf-m5--item p {
  margin: 0;
}
.luf-m5 .luf-m5--item:nth-child(n) {
  border-right: 1px solid #D8D8D8;
}
.luf-m5 .luf-m5--item:nth-child(n):after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1rem;
  right: -2rem;
  width: 4rem;
  height: 2rem;
  background-color: #fff;
  z-index: 1;
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-child(n):after {
    bottom: -1.5rem;
    right: -3.5rem;
    width: 7rem;
    height: 3rem;
  }
}
.luf-m5 .luf-m5--item:nth-child(2n) {
  border-right: 1px solid transparent;
}
.luf-m5 .luf-m5--item:nth-child(2n):after {
  background-color: transparent;
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-child(2n):after {
    background-color: #fff;
  }
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-child(2n) {
    border-right: 1px solid #D8D8D8;
  }
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-child(3n) {
    border-right: 1px solid transparent;
  }
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-last-child(-n+3) {
    border-bottom: 1px solid transparent;
  }
}
@media (min-width: 992px) {
  .luf-m5 .luf-m5--item:nth-last-child(-n+3):after {
    background-color: transparent;
  }
}
.luf-m5 .luf-m5--item:last-child, .luf-m5 .luf-m5--item:nth-last-child(2):nth-child(odd) {
  border-bottom: none;
}

.luf-m6 {
  position: relative;
  overflow: hidden;
}
.luf-m6 .luf-m6--intro {
  position: relative;
  max-width: 46.875rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.75rem;
  margin-bottom: 2.75rem;
  z-index: 5;
}

.luf-m7 {
  background-color: #fff;
  height: 100%;
}
.luf-m7 p {
  margin: 0;
}
.luf-m7--header {
  position: relative;
}
.luf-m7--header .luf-img:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 140px;
  width: 100%;
  z-index: 1;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(2, 0, 36, 0.4)), to(transparent));
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.7), transparent);
}
.luf-m7--headerinfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  z-index: 2;
}
.luf-m7--title {
  margin: 0;
}
.luf-m7--content {
  padding: 2rem;
}
.luf-m7--content p {
  margin: 0;
  min-height: 100px;
}
.luf-m7--content p.luf-text {
  display: -webkit-box;
  max-width: 100%;
  height: 100px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.luf-m7--details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2.375rem;
  padding-bottom: 1.25rem;
}
.luf-m7--footer {
  display: flex;
  border-top: 3px double #D8D8D8;
  padding-top: 1.5625rem;
  padding-bottom: 0.5rem;
}
.luf-m7--footer__cta {
  margin-right: 1.25rem;
}
.luf-m7--footer__details {
  max-width: 10.9375rem;
}
.luf-m7--footer__details p {
  line-height: 1.2;
  margin: 0;
}

.luf-m8 .luf-m8--intro {
  position: relative;
  max-width: 46.875rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.75rem;
  margin-bottom: 2.75rem;
  z-index: 5;
}
@media (min-width: 992px) {
  .luf-m8 .luf-m8--intro {
    margin-bottom: 6.8125rem;
  }
}
.luf-m8 .luf-icon {
  margin: 0;
}

.luf-m9 .luf-m9--heading {
  margin-bottom: 3.0625rem;
}
@media (min-width: 768px) {
  .luf-m9 .luf-m9--heading {
    margin-bottom: 6.125rem;
  }
}
.luf-m9 .luf-m9--item {
  margin-bottom: 1.875rem;
}
@media (min-width: 768px) {
  .luf-m9 .luf-m9--item {
    margin-bottom: 3.75rem;
  }
}
.luf-m9 .luf-m9--item:last-child, .luf-m9 .luf-m9--item:nth-last-child(2):nth-child(odd) {
  margin-bottom: 0;
}
.luf-m9 .luf-m9--icon {
  font-size: 4rem;
  margin-top: 0.5rem;
}
@media (min-width: 540px) {
  .luf-m9 .luf-m9--icon {
    font-size: 5rem;
  }
}
@media (min-width: 1140px) {
  .luf-m9 .luf-m9--icon {
    font-size: 7.4375rem;
  }
}
.luf-m9 .luf-m9--icon .luf-icon {
  display: block;
  margin: 0 auto;
}
.luf-m9 .luf-m9--title {
  margin-bottom: 0.625rem;
}

.luf-m10 {
  position: relative;
}
.luf-m10 [class*=col-] {
  position: static;
}
.luf-m10--img {
  position: relative;
  height: 100%;
}
.luf-m10--slider--slides {
  box-shadow: -40px 0 20px -20px rgba(53, 51, 52, 0.2);
}
@media (min-width: 1140px) {
  .luf-m10--slider--slides {
    box-shadow: none;
  }
}
.luf-m10--slider--thumbs {
  position: relative;
  height: 100%;
}
.luf-m10--slider--thumbs .slick-list, .luf-m10--slider--thumbs .slick-track {
  height: 100%;
  min-height: 800px;
}
@media (min-width: 768px) {
  .luf-m10--slider--thumbs .slick-list, .luf-m10--slider--thumbs .slick-track {
    min-height: auto;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .luf-m10--slider--thumbs .slick-list, .luf-m10--slider--thumbs .slick-track {
    min-height: 800px;
  }
}
.luf-m10--slider--thumbs .slick-slide {
  position: relative;
}
.luf-m10 .luf-m10--heading {
  margin-bottom: 2.375rem;
}
.luf-m10 .luf-m10--inner {
  width: 80%;
  max-width: 24.8125rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
}
@media (min-width: 1140px) {
  .luf-m10 .luf-m10--inner {
    width: auto;
    padding-top: 4.75rem;
    padding-bottom: 5.9375rem;
  }
}
.luf-m10 .luf-m10--content {
  background-color: #fff;
}
.luf-m10 .luf-m10--content .luf-m7--title {
  margin-bottom: 1rem;
}
.luf-m10 .luf-m10--content .luf-m7--content {
  padding: 0;
}
.luf-m10 .luf-m10--content .luf-m7--details {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 540px) {
  .luf-m10 .luf-m10--content .luf-m7--details {
    display: flex;
  }
}
.luf-m10 .luf-m10--content .luf-m7--footer__cta {
  margin-right: 1.875rem;
}

.luf-m11 {
  position: relative;
}
.luf-m11--slider--thumbs {
  position: relative;
  height: 100%;
}
.luf-m11--slider--thumbs .slick-list, .luf-m11--slider--thumbs .slick-track {
  height: 100%;
}
.luf-m11--slider--thumbs .slick-slide {
  position: relative;
}
.luf-m11 .luf-slider--control {
  position: absolute;
  bottom: 0.25rem;
  width: 120px;
  left: 49%;
  height: 6rem;
}
.luf-m11 .luf-slider--control .luf-slider--btn {
  padding: 1.5rem;
  border: 1px solid #D8D8D8;
}
.luf-m11 .luf-slider--control .luf-slider--btn:hover {
  border-color: #0095d9;
}
.luf-m11--intro [class*=col-] {
  position: static;
}
.luf-m11 .luf-m11--heading {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .luf-m11 .luf-m11--heading {
    transform: translateY(200%);
  }
}
.luf-m11 .luf-m11--content, .luf-m11 .luf-m11--heading {
  width: 90%;
  max-width: 25.4375rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .luf-m11 .luf-m11--content, .luf-m11 .luf-m11--heading {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .luf-m11 .luf-m11--inner {
    padding-top: 9.375rem;
    padding-bottom: 7rem;
  }
}
.luf-m11 .luf-m11--img, .luf-m11 .luf-lazy {
  padding-top: 74.81%;
}
@media (min-width: 768px) {
  .luf-m11 .luf-m11--img, .luf-m11 .luf-lazy {
    padding-top: 0;
  }
}

.luf-m12.luf-m12__right .luf-m12--inner {
  margin-left: auto;
}
.luf-m12.luf-m12__full {
  margin-right: 5%;
}
@media (min-width: 1400px) {
  .luf-m12.luf-m12__full {
    margin-right: 0;
  }
}
.luf-m12.luf-m12__full .luf-m12--img {
  padding-right: 30px;
}
@media (min-width: 1400px) {
  .luf-m12.luf-m12__full .luf-m12--img {
    padding-right: 60px;
  }
}
.luf-m12.luf-m12__full .luf-m12--inner {
  margin-left: 5%;
}
@media (min-width: 1140px) {
  .luf-m12.luf-m12__full .luf-m12--inner {
    padding-left: 60px;
  }
}
@media (min-width: 1400px) {
  .luf-m12.luf-m12__full .luf-m12--inner {
    margin-left: auto;
    margin-right: auto;
  }
}
.luf-m12 .luf-m12--inner {
  display: flex;
  align-items: center;
  max-width: 26.875rem;
  padding-top: 2.0625rem;
  padding-bottom: 2.0625rem;
}
.luf-m12 .luf-icon {
  width: 4em;
  height: 4em;
  margin-bottom: 0.8rem;
}

.luf-m13 {
  position: relative;
}
.luf-m13__right .luf-m13--img {
  margin-right: 0;
}
@media (min-width: 1140px) {
  .luf-m13__right .luf-m13--img {
    margin-left: calc((100vw - (1140px)) / 2 * -1);
  }
}
.luf-m13__right .luf-m13--inner {
  margin-left: auto;
  margin-right: 0;
}
.luf-m13 [class*=col-] {
  position: static;
}
@media (min-width: 1140px) {
  .luf-m13--img {
    margin-right: calc((100vw - (1140px)) / 2 * -1);
  }
}
.luf-m13--inner {
  max-width: 24.375rem;
  margin-right: auto;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .luf-m13--inner {
    margin-top: 0;
  }
}
.luf-m13--inner p {
  margin: 0;
}
.luf-m13--inner .btn {
  margin-top: 2rem;
}
.luf-m13--icon {
  width: 1.5rem;
  margin-bottom: 0.5rem;
}
.luf-m13--heading {
  margin-bottom: 1.375rem;
}

.tho-cp {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .tho-cp {
    margin-bottom: 6.5rem;
  }
}
.tho-cp:last-child {
  margin-bottom: 0;
}
.tho-cp a {
  color: #353334;
}
.tho-cp a:hover, .tho-cp a:focus {
  color: #0095d9;
}
.tho-cp--title {
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
}
.tho-cp--name {
  display: inline-block;
  font-size: 1.5rem;
  font-size: 19.2px;
  margin-bottom: 1.625rem;
}
@media (min-width: 320px) {
  .tho-cp--name {
    font-size: calc(19.2px + 4.8 * (100vw - 320px) / 820);
  }
}
@media (min-width: 1140px) {
  .tho-cp--name {
    font-size: 24px;
  }
}
.tho-cp--mail {
  box-shadow: 0 1px 0 0 #0095d9;
}
.tho-cp--item {
  margin-bottom: 1rem;
}
@media (min-width: 1140px) {
  .tho-cp--item {
    margin-bottom: 0;
  }
}
.tho-cp--item:last-child {
  margin-bottom: 0;
}
.tho-cp--item > .row {
  height: 100%;
}
.tho-cp--itemswrapper {
  position: relative;
  height: 100%;
}
@media (min-width: 992px) {
  .tho-cp--itemswrapper {
    background-color: #fff;
  }
}
.tho-cp--itemswrapper > .row {
  height: 100%;
}
.tho-cp--items {
  background-color: #fff;
  padding: 1rem;
  min-height: 100%;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .tho-cp--items {
    padding: 1rem 2rem;
    min-height: 13.25rem;
    min-height: 100%;
  }
}
@media (min-width: 992px) {
  .tho-cp--items {
    padding: 1.875rem 1.25rem;
    padding-left: 3rem;
  }
}
.tho-cp--image {
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.tho-cp--image__wrapper {
  width: 50%;
  max-width: 9.375rem;
}
@media (min-width: 992px) {
  .tho-cp--image__wrapper {
    position: absolute;
    margin-left: auto;
    max-width: 13.75rem;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .tho-cp--image__wrapper {
    left: 2rem;
  }
}

.luf-m14 {
  background-color: #f3f3f3;
}
.luf-m14:last-child {
  padding-bottom: 7.5rem;
}

article .tho-cp .tho-cp--image .luf-img--holder {
  background-color: transparent;
}

.luf-m16 {
  padding-bottom: 2.125rem;
  padding-top: 2.125rem;
}
@media (min-width: 992px) {
  .luf-m16 {
    padding-bottom: 4.25rem;
    padding-top: 4.25rem;
  }
}
@media (min-width: 992px) {
  .luf-m16--header {
    padding-right: 60px;
  }
}
.luf-m16--stickyheader {
  position: relative;
  line-height: 1.3;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 100%;
  color: white;
}
.luf-m16--stickyheader--inner {
  background-color: #0095d9;
  padding-top: 0.6875rem;
  padding-bottom: 0.5625rem;
  display: none;
}
.luf-m16 .luf-m7--details {
  padding-top: 0.9375rem;
  line-height: 1.2;
}
.luf-m16--date {
  display: flex;
}
.luf-m16--date__title {
  margin-right: 1.125rem;
}
.luf-m16--tags {
  margin-bottom: 1.25rem;
}
.luf-m16--tags li {
  display: inline-block;
}
.luf-m16--tags li a {
  color: #353334;
  box-shadow: 0 1px 0 0 #0095d9;
}
.luf-m16--tags li:not(:last-child) {
  border-right: 1px solid #D8D8D8;
  padding-right: 0.3rem;
}
.luf-m16--badges {
  font-size: 3.75rem;
}
.luf-m16--badges li {
  display: inline-block;
}
.luf-m16--badges li .luf-icon {
  display: block;
  margin-right: 0;
}
.luf-m16 .luf-flag {
  margin-left: -20px;
}
.luf-m16 .luf-img--copyright {
  right: initial;
  top: 0;
  bottom: initial;
}

.luf-m16-sticky {
  position: relative;
  z-index: 15;
}
@media (min-width: 768px) {
  .luf-m16-sticky.is_stuck .luf-m16--stickyheader {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .luf-m16-sticky.is_stuck .luf-m16--stickyheader .luf-m16--stickyheader--inner {
    display: block;
  }
}

.luf-m19 {
  padding-top: 1.25rem;
}
@media (min-width: 540px) {
  .luf-m19 {
    padding-top: 3rem;
  }
}
.luf-m19--introimage {
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 540px) {
  .luf-m19--introimage {
    margin-right: -0.9375rem;
  }
}
@media (min-width: 768px) {
  .luf-m19--introimage {
    margin-bottom: 0;
  }
}
.luf-m19--introimage .luf-tag--category {
  position: absolute;
  bottom: 13px;
  left: 0;
  margin: 0;
  padding: 0.25rem 1rem;
}
@media (min-width: 1140px) {
  .luf-m19--introimage .luf-tag--category {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .luf-m19--details {
    margin-left: -1.875rem;
  }
}
.luf-m19--badges {
  margin-top: 1.875rem;
}
@media (min-width: 540px) {
  .luf-m19--badges {
    margin-top: 0;
    height: 100%;
    padding-left: 1.875rem;
    border-left: 1px solid #D8D8D8;
  }
}
.luf-m19--badges li {
  display: inline-block;
}
@media (min-width: 540px) {
  .luf-m19--badges li {
    display: block;
  }
}
.luf-m19--badges .luf-icon {
  margin: 0;
  display: block;
  margin-bottom: 0.75rem;
}
.luf-m19--flags {
  padding-bottom: 1rem;
}
@media (min-width: 540px) {
  .luf-m19--flags {
    margin-left: -2.8rem;
  }
}
.luf-m19 .luf-descriptionlist {
  margin: 2rem 0;
  margin-bottom: 1rem;
}
@media (min-width: 540px) {
  .luf-m19 .luf-descriptionlist {
    margin-top: 0.9375rem;
    margin-bottom: 1.875rem;
  }
}
.luf-m19 .luf-trimline {
  margin-top: 2.5625rem;
}
.luf-m19 .luf-icon {
  width: 1.1em;
}
@media (min-width: 768px) {
  .luf-m19 .luf-icon {
    width: 1em;
  }
}

.luf-m20 {
  margin-bottom: 3.75rem;
}
@media (min-width: 768px) {
  .luf-m20 {
    margin-bottom: 5rem;
  }
}
.luf-m20--image {
  position: relative;
}
.luf-m20--image__icon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background-color: #0095d9;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.125rem;
}
.luf-m20--image__icon .luf-icon {
  display: block;
  fill: #fff;
  margin: 0;
}
.luf-m20--title {
  line-height: 1;
  margin-bottom: 0.5rem;
}
.luf-m20--subline {
  line-height: 1;
  margin-bottom: 1.0625rem;
}
@media (min-width: 768px) {
  .luf-m20--subline {
    margin-bottom: 2.9375rem;
  }
}
.luf-m20.luf-bus-card img {
  padding-top: 20px;
}
.luf-m20 .luf-img .luf-img--holder {
  background-color: #f3f3f3;
}

.luf-m22 {
  position: absolute;
  left: 0;
  top: 68vh;
  background-color: #fff;
  box-shadow: 0 1.25rem 1.875rem rgba(0, 0, 0, 0.5);
  border-radius: 0 0.625rem 0.625rem 0;
  transform: translateX(-110%);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  opacity: 0;
  visibility: hidden;
  z-index: 102;
  max-width: 350px;
}
.luf-m22.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
}
.luf-m22--close {
  padding: 0;
}
.luf-m22--content {
  margin-top: 0.5rem;
  padding-right: 1.875rem;
}
.luf-m22 .luf-m22-img {
  border-top-right-radius: 0.625rem;
}
.luf-m22 .luf-badge-inner {
  padding: 1.875rem;
}
.luf-m22 .luf-badge-inner.luf-badge-image {
  padding-top: 0px;
}
.luf-m22 .luf-tag--category {
  background-color: #353334;
  color: #fff;
}
.luf-m22 .luf-badge-image .luf-tag--category {
  position: absolute;
  top: 20px;
}
.luf-m22 .luf-badge-image .luf-m22--close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.luf-m22 .luf-badge-image .luf-m22--close .luf-navicon.open span {
  background: white;
}
.luf-m22 .luf-navicon {
  width: 1.25em;
  height: 1.25em;
}
@media (min-width: 768px) {
  .luf-m22 .luf-navicon {
    width: 0.75em;
    height: 0.75em;
  }
}
.luf-m22 .luf-navicon span {
  height: 0.125em;
}

.pdf-viewer {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 50px 0;
}

.flipbook-viewer {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #BBD2E6;
  color: #fff;
}

.flipbook-header {
  background: #4072A2;
  background: url(../images/flipbook-viewer/header.jpg) center center repeat;
  height: 40px;
}

.flipbook-header-logo {
  float: left;
  position: relative;
  top: 10px;
}

.flipbook-header-logo img {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  height: 20px;
  opacity: 0.5;
}

.flipbook-header-back-link {
  float: right;
  position: relative;
  top: 11px;
  font-size: 10px;
  font-weight: 700;
}

.flipbook-header-back-link a {
  color: #fff;
  text-decoration: underline;
}

.flipbook-header-back-link a:hover {
  color: #fff;
  text-decoration: none;
}

.flipbook-container {
  width: 1000px;
  margin: auto;
}

.flipbook-wrapper {
  margin: 40px 0;
}

.flipbook {
  display: none;
}

.flipbook.loaded {
  display: block;
  cursor: zoom-in;
}

.flipbook.zoom-in {
  cursor: zoom-out;
}

.flipbook-title-wrap {
  margin-bottom: 15px;
}

.flipbook-title {
  font-size: 22px;
  font-weight: 300;
  margin: 0;
  float: left;
}

.flipbook-title strong {
  font-weight: 700;
  text-transform: uppercase;
}

.search-input-wrapper {
  position: relative;
  float: right;
  width: 160px;
  background: #A3C2DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.search-input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  text-align: right;
  padding: 2px 10px;
  padding-right: 30px;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
}

.search-input::-webkit-input-placeholder {
  color: #fff;
}

.search-input::-moz-input-placeholder {
  color: #fff;
}

.search-input::input-placeholder {
  color: #fff;
}

.search-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  width: 14px;
  height: auto;
}

.flipbook-content {
  position: relative;
}

.flipbook-actions {
  position: absolute;
  top: 0;
  right: -45px;
}

.flipbook-action,
.flipbook-action:hover,
.flipbook-action:focus {
  cursor: pointer;
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background: #0095D9;
  box-shadow: 1px 2px 3px rgba(53, 51, 52, 0.4);
  margin-bottom: 10px;
}

.flipbook-action img {
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.flipbook-action-download img {
  width: 13px;
}

.flipbook-action-mail img {
  width: 17px;
}

.flipbook-action-print img {
  width: 15px;
}

.flipbook-nav-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0.5;
  -webkit-transition: opacity 340ms ease;
  -moz-transition: opacity 340ms ease;
  transition: opacity 340ms ease;
}

.flipbook-nav-arrow:hover {
  opacity: 1;
}

.flipbook-nav-arrow.disabled {
  opacity: 0;
}

.flipbook-nav-arrow-left {
  left: -67px;
}

.flipbook-nav-arrow-right {
  right: -67px;
}

.flipbook-footer {
  position: absolute;
  bottom: -40px;
  right: 0;
  font-size: 12px;
}

.flipbook-page-indicator {
  float: left;
}

.flipbook-page-indicator-text {
  font-size: 12px;
  font-weight: 300;
  margin-right: 10px;
  float: left;
}

.flipbook-action-overview {
  cursor: pointer;
  float: left;
  padding: 5px;
  margin-top: -5px;
}

.flipbook-action-zoom {
  cursor: pointer;
  float: left;
  padding: 5px;
  margin-top: -5px;
  margin-right: -5px;
}

.flipbook-page-indicator-input-wrapper {
  position: relative;
  top: -3px;
  float: right;
  width: auto;
  background: #A3C2DC;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 5px;
}

.flipbook-page-indicator-input-form {
  display: block;
  float: left;
}

.flipbook-page-indicator-input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  color: #fff;
  text-align: right;
  padding: 3px 0;
  font-size: 12px;
  font-weight: 300;
  width: 50px;
  float: left;
  font-weight: 700;
}

.flipbook-page-indicator-input-after {
  float: left;
  position: relative;
  top: 3px;
  padding-left: 5px;
  padding-right: 10px;
  font-weight: 300;
}

.flipbook-animated {
  -webkit-transition: margin-left 0.5s;
  -moz-transition: margin-left 0.5s;
  -ms-transition: margin-left 0.5s;
  -o-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.esc-notice {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 10000;
}

.esc-notice > div {
  width: 140px;
  height: 30px;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font: 12px arial;
  line-height: 30px;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.small-page {
  position: relative;
}

.large-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flipbook-content .small-page {
  height: 100%;
}

.flipbook-thumbnails {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 160px;
  overflow: auto;
  background: #4E6270;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  left: -160px;
  -webkit-transition: left 300ms ease;
  -moz-transition: left 300ms ease;
  transition: left 300ms ease;
}

.flipbook-thumbnails.visible {
  left: 0;
}

.flipbook-thumbnails-inner {
  padding: 20px;
  position: relative;
}

.flipbook-thumbnail {
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
  opacity: 0.7;
  -webkit-transition: opacity 340ms ease;
  -moz-transition: opacity 340ms ease;
  transition: opacity 340ms ease;
}

.flipbook-thumbnail.active,
.flipbook-thumbnail:hover {
  opacity: 1;
}

.page-right {
  width: 50%;
  float: right;
}

.page-left {
  width: 50%;
  float: left;
}

.page-number-indicator {
  position: absolute;
  top: 10px;
  left: -5px;
  padding: 2px 5px;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  color: #000;
  font-size: 10px;
}

.flipbook-thumbnail[data-page="1"] .page-number-indicator {
  left: auto;
  right: -5px;
}

.flipbook-search {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  overflow: auto;
  background: #4E6270;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  left: -320px;
  -webkit-transition: left 300ms ease;
  -moz-transition: left 300ms ease;
  transition: left 300ms ease;
}

.flipbook-search.visible {
  left: 0;
}

.flipbook-search-inner {
  padding: 20px;
  position: relative;
}

.flipbook-search-entry {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #a3c2dc;
  margin: 0 -20px;
  padding: 20px;
}

.flipbook-search-entry:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.flipbook-search-entry-image {
  float: left;
  position: relative;
  width: 25%;
}

.flipbook-search-entry-content {
  width: 70%;
  float: right;
}

.flipbook-search-entry-title {
  font-weight: 700;
  margin-bottom: 0.5em;
  font-size: 14px;
}

.flipbook-search-entry-text {
  font-size: 12px;
}

.flipbook-search-loading-indicator {
  position: relative;
  padding-top: 100px;
  display: none;
}

.flipbook-search-loading-indicator img {
  display: block;
  width: auto;
  height: auto;
  margin: auto;
}

.flipbook-search-loading-indicator.visible {
  display: block;
}

.flipbook-search-loading-indicator.visible + .flipbook-search-inner {
  display: none;
}

.flipbook-search-title {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.5em;
}

.flipbook-search-noentries {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

.mobile-only {
  display: none;
}

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.page-scrollbar {
  position: absolute;
  bottom: -28px;
  left: 0;
  height: 20px;
  width: 100%;
}

.page-scrollbar-background {
  position: relative;
  top: 7px;
  background: #A3C2DC;
  height: 6px;
  border-radius: 4px;
}

.page-scrollbar-fill {
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 4px;
  width: 50%;
  background: #fff;
  height: 6px;
}

.page-scrollbar-handle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}

.page-scrollbar-handle-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 12px;
}

@media screen and (max-width: 900px) {
  html.flipbook-body,
  body.flipbook-body {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: -webkit-fill-available;
  }
  .mobile-only {
    display: block;
  }
  .flipbook-header {
    background: none;
    height: auto;
  }
  .flipbook-container {
    width: auto !important;
    padding: 0 10px;
  }
  .flipbook-header-logo {
    display: none !important;
  }
  .flipbook-header-back-link {
    float: none;
    padding: 10px;
    top: auto;
  }
  .flipbook-header-back-link a {
    color: #0095D9;
  }
  .flipbook-wrapper {
    margin: 0;
  }
  .flipbook-title-wrap {
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .flipbook-title-wrap .search-input-wrapper {
    display: none;
  }
  .flipbook-title {
    font-size: 20px;
  }
  .flipbook-nav-arrow,
  .flipbook-footer {
    bottom: -55px;
  }
  .flipbook-nav-arrow {
    top: auto;
    opacity: 1;
  }
  .flipbook-nav-arrow img {
    height: 20px;
    width: auto;
  }
  .flipbook-nav-arrow-left {
    left: 50%;
    right: auto;
    transform: translate(-42px, 0);
  }
  .flipbook-nav-arrow-right {
    left: 50%;
    right: auto;
    transform: translate(70px, 0);
  }
  .flipbook-actions {
    display: none !important;
  }
  .flipbook-footer {
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .flipbook-page-indicator-text {
    display: none !important;
  }
  .flipbook-page-indicator-input-form {
    display: none !important;
  }
  .flipbook-page-indicator-input-wrapper {
    margin-right: 0;
    width: auto;
    background: none;
    top: -2px;
  }
  .flipbook-page-indicator-input-after {
    padding: 0;
    top: auto;
  }
  .flipbook-page-indicator-input-before {
    display: inline-block;
    font-weight: 700;
  }
  .flipbook-action-zoom {
    display: none !important;
  }
  .flipbook-action-overview {
    position: absolute;
    margin: 0;
    left: 50%;
    margin-left: 30px;
    top: -7px;
  }
  .flipbook-action-overview img {
    width: 18px;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .flipbook {
    display: none !important;
  }
  .flipbook-slider {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .flipbook-slider-page {
    float: left;
    height: 100%;
    width: 100%;
  }
  .flipbook-slider-page-image {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .flipbook-slider-page-image img {
    display: none;
    pointer-events: none;
  }
  .flipbook-slider-page.current .flipbook-slider-page-image img {
    display: block;
  }
  .flipbook-slider-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .flipbook-slider-mover {
    position: relative;
    height: 100%;
  }
  .flipbook-slider-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .flipbook-slider-page-image.animate {
    -webkit-transition: transform 500ms ease;
    -moz-transition: transform 500ms ease;
    -ms-transition: transform 500ms ease;
    -o-transition: transform 500ms ease;
    transition: transform 500ms ease;
  }
  .flipbook-title-wrap {
    position: relative;
  }
  .menu-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 -10px;
  }
  .menu-icon .dot {
    width: 8px;
    height: 8px;
    background: #0095D9;
    border-radius: 8px;
    margin-top: 3px;
  }
  .menu-icon .dot:first-child {
    margin-top: 0;
  }
  .flipbook-menu {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(67, 87, 100, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: opacity 340ms ease;
  }
  .flipbook-menu.visible {
    opacity: 1;
    pointer-events: auto;
  }
  .flipbook-menu .flipbook-title-wrap {
    padding-left: 20px;
    position: relative;
  }
  .flipbook-menu .close-icon {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 10px;
    transform: translate(0, -50%);
  }
  .flipbook-menu .close-icon img {
    width: 19px;
    height: auto;
    display: block;
    margin: 0;
  }
  .flipbook-menu-inner {
    padding: 30px 20px;
    position: relative;
  }
  .flipbook-menu .search-input-wrapper {
    float: none;
    width: auto;
    background: rgba(255, 255, 255, 0.3);
  }
  .flipbook-menu .search-input {
    font-size: 16px;
    padding: 7px 38px 7px 10px;
  }
  .flipbook-menu .search-icon {
    width: 19px;
    top: 10px;
  }
  .flipbook-menu .flipbook-actions {
    display: block !important;
    position: relative;
    right: auto;
    padding-top: 16px;
  }
  .flipbook-menu .flipbook-action {
    width: 86px;
    height: 86px;
    border-radius: 86px;
    margin: auto;
    margin-top: 24px;
  }
  .flipbook-menu .flipbook-action-download img {
    width: 31px;
  }
  .flipbook-menu .flipbook-action-mail img {
    width: 41px;
  }
  .flipbook-menu .flipbook-action-print img {
    width: 37px;
  }
  .flipbook-search {
    width: 100%;
    left: 0;
    top: auto;
    bottom: -100vh;
    height: calc(100vh - 190px);
    transition: bottom 0.3s ease;
    overflow: visible;
  }
  .flipbook-search.visible {
    bottom: 0;
  }
  .flipbook-search-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
  }
}
@font-face {
  font-family: "lg";
  src: url("/bundles/lueftner/fonts/lg.eot?n1z373");
  src: url("/bundles/lueftner/fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("/bundles/lueftner/fonts/lg.woff?n1z373") format("woff"), url("/bundles/lueftner/fonts/lg.ttf?n1z373") format("truetype"), url("/bundles/lueftner/fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lg-icon {
  font-family: "lg";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #FFF;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("/bundles/lueftner/images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("/bundles/lueftner/images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("/bundles/lueftner/images/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("/bundles/lueftner/images/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("/bundles/lueftner/images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.group {
  *zoom: 1;
}

.group:before, .group:after {
  display: table;
  content: "";
  line-height: 0;
}

.group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  background: url("/bundles/lueftner/images/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.luf-lightbox button {
  border: 0;
  border-radius: 0;
}