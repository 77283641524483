.luf-m8 {
  & &--intro {
    position: relative;
    max-width: rem-calc(750);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(44);
    margin-bottom: rem-calc(44);
    z-index: 5;

    @include media-breakpoint-up(md) {
      margin-bottom: rem-calc(109);
    }
  }

  .luf-icon {
    margin: 0;
  }
}