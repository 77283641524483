.luf-container{

  &.accordion {

    .card {
      border: none;

      .collapse {
        &.show {
          background-color: #f4f4f4;
        }
      }
    }

    .card-header {
      padding: 1.5rem;
      min-height: 60px;
      background-color: #ffffff;

      .btn-primary {
        margin-top: 0.5rem;
        @include media-breakpoint-up(xs) {
          margin-top: 0px;
        }
      }

      .news-header-text {
        padding-top: 0.5rem;
      }
    }

    .pagination {
      list-style: none;
      display: block;
      text-align: center;

      li {
        line-height: 1;
        position: relative;
        width: 50px;
        height: 50px;
        margin: 0 5px;
        display: inline-block;

        &.active{
          background-color: #f4f4f4;
        }

        a, span {
          width: auto;
          height: auto;
          border-radius: 0;
          border: none;
          line-height: 1;
          padding: 0;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }

      li:hover {
        background-color: #f4f4f4;
      }
    }
  }
}