.luf-m14 {
  background-color: #f3f3f3;

  &:last-child {
    padding-bottom: rem-calc(120);
  }
}

article {
  .tho-cp {
    .tho-cp--image{
      .luf-img--holder{
        background-color: transparent;
      }
    }
  }
}