.luf-m19 {
  padding-top: rem-calc(20);

  @include media-breakpoint-up(xs) {
    padding-top: rem-calc(48);
  }
  //introimage
  &--introimage {
    position: relative;
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(xs) {
      margin-right: - rem-calc($grid-gutter-width / 2);
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }

    .luf-tag--category {
      position: absolute;
      bottom: 13px;
      left:0;
      margin: 0;
      padding: 0.25rem 1rem;

      @include media-breakpoint-up(lg) {
        bottom: 0;
      }
    }
  }
  //details
  &--details {

    @include media-breakpoint-up(sm) {
      margin-left: - rem-calc($grid-gutter-width);
    }
  }
  //badges
  &--badges {

    margin-top: rem-calc(30);

    @include media-breakpoint-up(xs) {
      margin-top: 0;
      height: 100%;
      padding-left: rem-calc($grid-gutter-width);
      border-left: 1px solid $gray-400;
    }

    &.has-flag {
      //padding-top: 4rem;
    }

    li {
      display: inline-block;

      @include media-breakpoint-up(xs) {
        display: block;
      }
    }

    .luf-icon {
      margin: 0;
      display: block;
      margin-bottom: rem-calc(12);
    }
  }
  //flags
  &--flags {

    padding-bottom: 1rem;
    @include media-breakpoint-up(xs) {
      margin-left: -2.8rem;
    }
  }
  //descriptionlist adjustments
  // see component description list
  .luf-descriptionlist {
    margin: 2rem 0;
    margin-bottom: 1rem;
    @include media-breakpoint-up(xs) {
      margin-top: 0.9375rem;
      margin-bottom: 1.875rem;
    }
  }

  .luf-trimline {
    margin-top: rem-calc(41);
  }

  .luf-icon {
    width: 1.1em;

      @include media-breakpoint-up(sm) {
        width: 1em;
      }
  }
}