.luf-m12 {

  &.luf-m12__right  {
    .luf-m12--inner {
      margin-left: auto;
    }
  }

  &.luf-m12__full {
    margin-right: 5%;
    @include media-breakpoint-up(xl) {
      margin-right: 0;
    }

    .luf-m12--img {
      padding-right: $grid-gutter-width;

      @include media-breakpoint-up(xl) {
        padding-right: $grid-gutter-width * 2;
      }
    }
    .luf-m12--inner {
      margin-left: 5%;

      @include media-breakpoint-up(lg) {

        padding-left: $grid-gutter-width * 2;
      }
      @include media-breakpoint-up(xl) {
        margin-left: auto;
        margin-right: auto;
      }

    }
  }
   & &--inner {
     display: flex;
     align-items: center;
     max-width: rem-calc(430);
     padding-top: rem-calc(33);
     padding-bottom: rem-calc(33);
   }

  .luf-icon {
    width: 4em;
    height: 4em;
    margin-bottom: 0.8rem;
  }
}
