$term-width: 8.5rem;

.luf {
  &-descriptionlist {
    @include luf-ft-s-2;
    margin-top: rem-calc($grid-gutter-width / 2);
    margin-bottom: rem-calc($grid-gutter-width);

    @include media-breakpoint-up(xs) {
      @include luf-ft-sn;
    }

    .luf-icon {
      margin-right: rem-calc(14);
    }
    dl {
      display: flex;
      flex-flow: row wrap;
    }
    dt,dd {
      margin: 0;
      margin-bottom: 1rem;
      word-wrap: break-word;
      line-height: 1;
    }
    dt {
      width: $term-width;
      display: flex;
    }
    dd {
      width: calc(100% - #{$term-width} - 8px);
    }
  }
}