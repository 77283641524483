.luf-m3 {
  position: relative;
  height: 100%;


  .luf-icon {
    margin: 0;
    fill: currentColor;
  }

  & &--inner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    min-height: 600px;

    padding-top: rem-calc(62);
    padding-bottom: rem-calc(67);
  }
  & &--content {
    position: relative;
    width: 80%;
    max-width: rem-calc(526);
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
  }
  & &--title {
    //margin-bottom: rem-calc(280);

    &__inner {
      display: block;
    }
  }

}