.luf-gallery {

  &--slides {
    .slick-slide {
      margin-left:27px;
    }

    /* the parent */
    .slick-list {
      margin-left:-27px;
    }
  }
  &--thumbs {
    .slick-slide {
      margin-left:10px;
    }

    /* the parent */
    .slick-list {
      margin-left:-10px;
    }
  }
}