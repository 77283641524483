.luf-m6 {
  position: relative;
  overflow: hidden;

  & &--intro {
    position: relative;
    max-width: rem-calc(750);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(44);
    margin-bottom: rem-calc(44);
    z-index: 5;
  }
}