.luf-m7 {
  background-color: $white;
  height: 100%;

  p {
    margin: 0;
  }

  &--header {
    position: relative;

    .luf-img:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 140px;
      width: 100%;
      z-index: 1;
      background: -webkit-gradient(linear,left top,left bottom,from(rgba(2,0,36,.4)),to(transparent));
      background: linear-gradient(0deg,rgba(2,0,36,.7),transparent);
    }
  }
  &--headerinfo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    z-index: 2;
  }
  &--title {
    margin: 0;
  }
  &--category {

  }

  &--content {
    padding: 2rem;

    p {
      margin: 0;
      min-height: 100px;

      &.luf-text {
        display: -webkit-box;
        max-width: 100%;
        height: 100px;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  &--details {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: rem-calc(38);
    padding-bottom: rem-calc(20);
  }

  &--footer {
    display: flex;
    //align-items: center;
    border-top: 3px double $gray-400;
    padding-top: rem-calc(25);
    padding-bottom: 0.5rem;

    &__cta {
      margin-right: rem-calc(20);
    }
    &__details {
      max-width: rem-calc(175);
      p {
        line-height: 1.2;
        margin: 0;
      }
    }
  }
}