//Strip unit
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

//px to rem us rem-calc(11)
@function rem-calc($size) { $stripunit: strip-unit($size); $remSize:  ($stripunit / strip-unit($font-size-default)); @return $remSize * 1rem; }


//px to em us rem-calc(11)
@function em-calc($size) { $stripunit: strip-unit($size); $emSize:  ($stripunit / strip-unit($font-size-default)); @return $emSize * 1em; }

/* fluid typo */

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

//arrow

@mixin arrow($size: 1rem, $color: $primary, $angle: 45, $thick: 3, $direction: 90, $bright: 30%, $aleft: 32%) {

  display: inline-block;
  position: absolute;
  top: 50%;
  right: 50%;
  margin: auto;
  padding: 0;
  width: $size;
  height: $size;
  transform: rotate(#{$direction}deg) translateX(50%) translateY(-50%);

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    //left:50%;
    height: 78%;
    width: rem-calc($thick);
    background: $color;
    border-radius: 3px;

  }

  &:before {
    right: $bright;
    transform: rotate(#{$angle}deg);
    transform-origin: right;
  }

  &:after {
    left: $aleft;
    transform: rotate(#{- $angle}deg);
    transform-origin: left;
  }

  &.open {
    &:before {
      transform: rotate(#{- $angle}deg);
    }
    &:after {
      transform: rotate(#{$angle}deg);
    }
  }
}

@mixin triangle-up($size: 10%, $color: $primray) {
  width: $size * 2;
  height: 0;
  padding-left:$size;
  padding-bottom: $size;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left: -500px;
    border-left: 500px solid transparent;
    border-right: 500px solid transparent;
    border-bottom: 500px solid $color;
  }
}